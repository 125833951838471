import React from 'react';

const Calendar = ({ fill, ...props }: any) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M19 3.00012H18V1.00012H16V3.00012H8V1.00012H6V3.00012H5C4.46957 3.00012 3.96086 3.21084 3.58579 3.58591C3.21071 3.96098 3 4.46969 3 5.00012V19.0001C3 19.5306 3.21071 20.0393 3.58579 20.4143C3.96086 20.7894 4.46957 21.0001 5 21.0001H10V19.0001H5V8.00012H19V9.00012H21V5.00012C21 4.46969 20.7893 3.96098 20.4142 3.58591C20.0391 3.21084 19.5304 3.00012 19 3.00012ZM21.7 13.3501L20.7 14.3501L18.65 12.3501L19.65 11.3501C19.85 11.1401 20.19 11.1301 20.42 11.3501L21.7 12.6301C21.89 12.8301 21.89 13.1501 21.7 13.3501ZM12 18.9401L18.07 12.8801L20.12 14.8801L14.06 21.0001H12V18.9401Z"
      fill={fill}
    />
  </svg>
);

export default Calendar;
