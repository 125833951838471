import React from 'react';

const People = ({ fill, ...props }: any) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M16.6704 13.13C18.0404 14.06 19.0004 15.32 19.0004 17V20H23.0004V17C23.0004 14.82 19.4304 13.53 16.6704 13.13Z"
      fill={fill}
    />
    <path
      d="M15.0004 12C17.2104 12 19.0004 10.21 19.0004 8C19.0004 5.79 17.2104 4 15.0004 4C14.5304 4 14.0904 4.1 13.6704 4.24C14.5004 5.27 15.0004 6.58 15.0004 8C15.0004 9.42 14.5004 10.73 13.6704 11.76C14.0904 11.9 14.5304 12 15.0004 12Z"
      fill={fill}
    />
    <path
      d="M9.00049 12C11.2105 12 13.0005 10.21 13.0005 8C13.0005 5.79 11.2105 4 9.00049 4C6.79049 4 5.00049 5.79 5.00049 8C5.00049 10.21 6.79049 12 9.00049 12ZM9.00049 6C10.1005 6 11.0005 6.9 11.0005 8C11.0005 9.1 10.1005 10 9.00049 10C7.90049 10 7.00049 9.1 7.00049 8C7.00049 6.9 7.90049 6 9.00049 6Z"
      fill={fill}
    />
    <path
      d="M9.00025 13C6.33024 13 1.00024 14.34 1.00024 17V20H17.0002V17C17.0002 14.34 11.6702 13 9.00025 13ZM15.0002 18H3.00024V17.01C3.20024 16.29 6.30024 15 9.00025 15C11.7002 15 14.8002 16.29 15.0002 17V18Z"
      fill={fill}
    />
  </svg>
);

export default People;
