import React from 'react';

const Storage = ({ fill, ...props }: any) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M2 19C2 19.5523 2.44772 20 3 20H21C21.5523 20 22 19.5523 22 19V17C22 16.4477 21.5523 16 21 16H3C2.44772 16 2 16.4477 2 17V19ZM4 18C4 17.4477 4.44772 17 5 17C5.55228 17 6 17.4477 6 18C6 18.5523 5.55228 19 5 19C4.44772 19 4 18.5523 4 18ZM3 4C2.44772 4 2 4.44772 2 5V7C2 7.55228 2.44772 8 3 8H21C21.5523 8 22 7.55228 22 7V5C22 4.44772 21.5523 4 21 4H3ZM6 6C6 6.55228 5.55228 7 5 7C4.44772 7 4 6.55228 4 6C4 5.44772 4.44772 5 5 5C5.55228 5 6 5.44772 6 6ZM2 13C2 13.5523 2.44772 14 3 14H21C21.5523 14 22 13.5523 22 13V11C22 10.4477 21.5523 10 21 10H3C2.44772 10 2 10.4477 2 11V13ZM4 12C4 11.4477 4.44772 11 5 11C5.55228 11 6 11.4477 6 12C6 12.5523 5.55228 13 5 13C4.44772 13 4 12.5523 4 12Z"
      fill={fill}
    />
  </svg>
);

export default Storage;
