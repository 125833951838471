import React from 'react';

const Refurbed = ({ fill, ...props }: any) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M6.99994 9.50996C6.15841 10.2918 5.50453 11.2538 5.08719 12.324C4.66986 13.3941 4.49987 14.5448 4.58994 15.69H4.43994C4.22994 15.28 4.00994 14.88 3.82994 14.47C2.71511 11.9877 2.45487 9.2059 3.08994 6.55996C3.14316 6.3154 3.26857 6.09245 3.44994 5.91997C5.10826 4.32841 7.19235 3.25164 9.44994 2.81996C10.3196 2.67638 11.2117 2.8541 11.9599 3.31996C13.8831 4.40565 15.3258 6.17693 15.9999 8.27996C16.0133 8.3499 16.0443 8.41529 16.0899 8.46996C15.9335 7.21261 15.4606 6.0155 14.7156 4.99064C13.9706 3.96578 12.9777 3.14667 11.8299 2.60996C12.3484 2.50908 12.8815 2.50908 13.3999 2.60996C16.0626 2.91156 18.5565 4.06557 20.5099 5.89997C20.7252 6.09305 20.8754 6.34806 20.9399 6.62996C21.425 8.80541 21.3354 11.0697 20.6799 13.2C20.4183 13.9972 19.8927 14.6815 19.1899 15.14C17.7192 16.1544 15.9766 16.7016 14.1899 16.71C14.0805 16.6948 13.9694 16.6948 13.8599 16.71C14.85 16.9152 15.8718 16.9137 16.8613 16.7055C17.8508 16.4972 18.7865 16.0868 19.6099 15.5C19.612 15.6426 19.5622 15.7812 19.4699 15.89C17.8691 18.5358 15.3422 20.4924 12.3799 21.38C12.1887 21.4455 11.9811 21.4455 11.7899 21.38C9.4586 20.7226 7.37205 19.3941 5.78994 17.56C5.33525 16.9913 5.08203 16.2879 5.06994 15.56C4.97912 13.5012 5.59287 11.473 6.80994 9.80997L6.92994 9.65996C6.92994 9.65996 6.93994 9.63997 6.99994 9.50996ZM8.33994 11.31C8.35018 12.2803 8.73814 13.2084 9.42148 13.8973C10.1048 14.5863 11.0297 14.9818 11.9999 15C12.7354 15.0039 13.4554 14.7892 14.0686 14.3831C14.6818 13.9769 15.1604 13.3977 15.4436 12.719C15.7269 12.0403 15.8021 11.2927 15.6596 10.5711C15.5171 9.8496 15.1633 9.18671 14.6433 8.66665C14.1232 8.1466 13.4603 7.79285 12.7388 7.65034C12.0173 7.50782 11.2696 7.58298 10.5909 7.86625C9.9122 8.14953 9.33298 8.62815 8.92685 9.24131C8.52071 9.85447 8.30599 10.5745 8.30994 11.31H8.33994Z"
      fill={fill}
    />
  </svg>
);

export default Refurbed;
