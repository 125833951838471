import React from 'react';

const Timer = ({ fill, ...props }: any) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M15.0699 1.01001H9.06995V3.01001H15.0699V1.01001ZM11.0699 14.01H13.0699V8.01001H11.0699V14.01ZM19.0999 7.39001L20.5199 5.97001C20.0899 5.46001 19.6199 4.98001 19.1099 4.56001L17.6899 5.98001C16.1399 4.74001 14.1899 4.00001 12.0699 4.00001C7.09995 4.00001 3.06995 8.03001 3.06995 13C3.06995 17.97 7.08995 22 12.0699 22C17.0499 22 21.0699 17.97 21.0699 13C21.0699 10.89 20.3299 8.94001 19.0999 7.39001ZM12.0699 20.01C8.19995 20.01 5.06995 16.88 5.06995 13.01C5.06995 9.14001 8.19995 6.01001 12.0699 6.01001C15.9399 6.01001 19.0699 9.14001 19.0699 13.01C19.0699 16.88 15.9399 20.01 12.0699 20.01Z"
      fill={fill}
    />
  </svg>
);

export default Timer;
