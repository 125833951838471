import React from 'react';

const Return = ({ fill, ...props }: any) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M12 14.0001H16V10.0001H12V7.00012L7 12.0001L12 17.0001V14.0001ZM19 3.00012H14.82C14.4 1.84012 13.3 1.00012 12 1.00012C10.7 1.00012 9.6 1.84012 9.18 3.00012H5C4.86 3.00012 4.73 3.01012 4.6 3.04012C4.21 3.12012 3.86 3.32012 3.59 3.59012C3.41 3.77012 3.26 3.99012 3.16 4.23012C3.06 4.46012 3 4.72012 3 5.00012V19.0001C3 19.2701 3.06 19.5401 3.16 19.7801C3.26 20.0201 3.41 20.2301 3.59 20.4201C3.86 20.6901 4.21 20.8901 4.6 20.9701C4.73 20.9901 4.86 21.0001 5 21.0001H19C20.1 21.0001 21 20.1001 21 19.0001V5.00012C21 3.90012 20.1 3.00012 19 3.00012ZM12 2.75012C12.41 2.75012 12.75 3.09012 12.75 3.50012C12.75 3.91012 12.41 4.25012 12 4.25012C11.59 4.25012 11.25 3.91012 11.25 3.50012C11.25 3.09012 11.59 2.75012 12 2.75012ZM19 19.0001H5V5.00012H19V19.0001Z"
      fill={fill}
    />
  </svg>
);

export default Return;
