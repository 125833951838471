import React from 'react';

const Backmarket = ({ fill, ...props }: any) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M21.0001 5L18.6101 7.7C17.3901 9.07 16.1701 10.45 14.9401 11.82C14.8101 11.96 14.8201 12.03 14.9401 12.17L20.7501 18.71L21.0001 19C20.9301 19.07 20.8601 19 20.7901 19H17.3001C17.2347 19.0024 17.1698 18.9891 17.1106 18.9612C17.0515 18.9334 16.9999 18.8918 16.9601 18.84C14.9867 16.6133 13.0234 14.3967 11.0701 12.19C10.9501 12.05 10.9301 11.97 11.0701 11.83C13.0701 9.6 15.0701 7.36 17.0101 5.13C17.0315 5.09262 17.062 5.06117 17.0986 5.03847C17.1353 5.01578 17.177 5.00256 17.2201 5H21.0001Z"
      fill={fill}
    />
    <path
      d="M13 5L10.42 7.9C9.26001 9.23333 8.09334 10.5467 6.92001 11.84C6.81001 11.97 6.82001 12.03 6.92001 12.14L13 19C12.95 19.07 12.88 19 12.83 19H9.29001C9.23289 18.9962 9.1773 18.98 9.12718 18.9523C9.07706 18.9247 9.03364 18.8863 9.00001 18.84C7.00001 16.6133 5.00001 14.38 3.00001 12.14C2.90001 12.03 2.91001 11.97 3.00001 11.86C5.00001 9.64 7.00001 7.41333 9.00001 5.18C9.04033 5.12723 9.0916 5.08382 9.15029 5.05275C9.20898 5.02168 9.2737 5.00368 9.34001 5H13Z"
      fill={fill}
    />
  </svg>
);

export default Backmarket;
